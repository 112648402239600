import { api } from './api';
import { COUNT_SEARCH_GLOBAL } from 'constant';

//Глобальный поиск
//query	string Обязат.
//indexName	string
//limit	integer (int32)
export const globalSearch = data => {
	return api.get('/search-v2/find', {
		params: {
			...data,
			...(!data.noLimit && { limit: COUNT_SEARCH_GLOBAL }),
		},
	});
};

//Глобальный поиск по контактам для отображение списка юзеров, сгруппированных по подразделениям
//query	string Обязат.
//indexName	string Обязат.
//limit	integer (int32)
export const globalSearchContacts = data => {
	return api.get('/search/find-index-with-grouping', {
		params: {
			...data,
		},
	});
};

export const globalSearchWithPagination = data => {
	return api.get('/search/find-pagination', {
		params: {
			...data,
		},
	});
};

//Создание индекса для всех сущностей для поиска
export const searchCreateIndex = () => {
	api.post('/search/create')
		.then(function (response) {
			if (response.data.responseCode === 0) {
				console.log('индексы для сущностей созданы');
			} else {
				console.log(`Ошибка - ${response.data.responseCode} ${response.data.errorMessage}`);
			}
		})
		.catch(function (error) {
			console.log(`Ошибка - ${error}`);
		});
};

export const getSearchHistoryList = async () => {
	try {
		const response = await api.get('/search-history/list');
		return response.data;
	} catch (error) {
		return {
			errorMessage: `${error}`,
			response: [],
			responseCode: 1,
		};
	}
};

export const searchHistoryDeleteRest = async (entityId, indexName) => {
	try {
		const response = await api.post(`/search-history/delete`, {
			indexName,
			entityId,
		});
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};
