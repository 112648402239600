import React from 'react'
import { connect } from 'react-redux'

import { openHeadMenu } from '../../../../action/ui'

import './style.scss'

const UserArrow = ({ ui, openHeadMenu }) => {

    const openHandler = () => {
        openHeadMenu()
    }

    return (
        <svg role="img" className={ui.headerMenu ? "user-arrow user-arrow_open" : "user-arrow"} onClick={openHandler}>
            <use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#mini-arrow'}></use>
        </svg>
    )
}

const mapStateToProps = (state) => {
    return {
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openHeadMenu: () => {
            dispatch(openHeadMenu())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(UserArrow)
