import React from 'react';
import { Link } from 'react-router-dom';

import { clearStoreSearchResult, textColor } from '../../utils';

import './style.scss';

export const ClubItem = ({ id, name, fillText, indexName, closeHandle }) => {
	const clickHandle = () => {
		if (closeHandle) closeHandle();
		clearStoreSearchResult();
	};
	return (
		<Link
			to={`/club/partner/${id}?fromIndex=${indexName}`}
			onClick={clickHandle}
			className="block-item block-club"
		>
			{textColor(name, fillText)}
		</Link>
	);
};
