import React, { useState, useEffect } from 'react';
import { Datepicker, TimePicker } from 'core/components';

import { getHours, getMinutes } from 'date-fns';

import './style.scss';

const defaultHourMinute = date => {
	if (date) {
		const defaultValue = new Date(date);

		return `${
			defaultValue.getHours() < 10 ? `0${defaultValue.getHours()}` : defaultValue.getHours()
		}:${
			defaultValue.getMinutes() < 10
				? `0${defaultValue.getMinutes()}`
				: defaultValue.getMinutes()
		}`;
	} else {
		return '';
	}
};

export const InputsDateTime = ({
	title,
	defaultDate,
	error = false,
	onChange,
	name,
	disabledPrevDays = false,
	disableTo = 0,
	disableFrom = 0,
	onInput,
	onClear = false,
}) => {
	const [clearDate, setClearDate] = useState(false);
	const [defaultDay, setDefaultDay] = useState('');
	const [defaultTime, setDefaultTime] = useState(defaultHourMinute(defaultDate));
	const [date, setDate] = useState(null);
	const [time, setTime] = useState(null);

	useEffect(() => {
		if (onChange) onChange(time, date, name);
	}, [time, date, onChange, name]);

	useEffect(() => {
		if (onClear) {
			setClearDate(true);
		}
	}, [onClear, setDefaultTime, setDate, setTime]);

	const dateHandler = date => setDate(date);
	const changeTime = time => setTime(time);

	useEffect(() => {
		if (defaultDate) {
			const defaultValue = new Date(defaultDate);
			setDefaultDay(
				defaultDate -
					(defaultValue.getHours() * 3600 + defaultValue.getMinutes() * 60) * 1000
			);

			const hours = getHours(defaultDate);
			const minutes = getMinutes(defaultDate);
			setDefaultTime(
				`${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
			);
		} else {
			setDefaultTime('');
		}
	}, [defaultDate, setDefaultTime, setDefaultDay]);

	return (
		<div className="inputs">
			<span className="inputs-title">{title}</span>
			<div className="inputs-container">
				<div className="inputs-datepicker">
					<Datepicker
						clearDate={clearDate}
						setClearDate={setClearDate}
						onChange={dateHandler}
						onInput={() => onInput && onInput(name)}
						initialValue={!!defaultDay && defaultDay}
						placeholder="Выберите дату"
						error={error}
						disabledPrevDays={disabledPrevDays}
						disableTo={disableTo}
						disableFrom={disableFrom}
					/>
				</div>
				<TimePicker
					onClear={clearDate}
					onChange={changeTime}
					defaultValue={defaultTime}
					name="time"
					error={!!error}
				/>
			</div>
		</div>
	);
};
