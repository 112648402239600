import React, {
	forwardRef,
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo,
	Fragment,
	cloneElement,
} from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import useControlled from 'core/utils/useControlled';
import useForkRef from 'core/utils/useForkRef';
import setRef from 'core/utils/setRef';
import { useIsFocusVisible } from 'core/utils/focusVisible';
import { duration } from 'core/utils/transitions';

import Popper from '../popper';
import TransitionGrow from '../transitionGrow';

import './style.scss';

let delayOpen = false;
let delayTimer = null;

const Tooltip = forwardRef(function Tooltip(props, ref) {
	const {
		arrow = true,
		className,
		children,
		disableFocusListener = false,
		disableHoverListener = false,
		disableTouchListener = false,
		enterDelay = 0,
		enterTouchDelay = 100,
		id: idProp,
		interactive = false,
		leaveDelay = 0,
		leaveTouchDelay = 1500,
		onClose = undefined,
		onOpen = undefined,
		open: openProp,
		placement = 'top',
		PopperProps,
		title,
		TransitionComponent = TransitionGrow,
		TransitionProps,
		...other
	} = props;

	const [childNode, setChildNode] = useState();
	const ignoreNonTouchEvents = useRef(false);

	const closeTimer = useRef();
	const enterTimer = useRef();
	const leaveTimer = useRef();
	const touchTimer = useRef();

	const [openState, setOpenState] = useControlled({
		controlled: openProp,
		default: false,
	});
	let open = openState;

	const [defaultId, setDefaultId] = useState();
	const id = idProp || defaultId;
	useEffect(() => {
		if (!open || defaultId) return;
		setDefaultId(`tooltip-${Math.round(Math.random() * 1e5)}`);
	}, [open, defaultId]);

	useEffect(() => {
		return () => {
			clearTimeout(closeTimer.current);
			clearTimeout(enterTimer.current);
			clearTimeout(leaveTimer.current);
			clearTimeout(touchTimer.current);
		};
	}, []);

	const handleOpen = event => {
		clearTimeout(delayTimer);
		delayOpen = true;
		setOpenState(true);

		if (onOpen) {
			onOpen(event);
		}
	};

	const handleEnter = event => {
		const childrenProps = children.props;

		if (
			event.type === 'mouseover' &&
			childrenProps.onMouseOver &&
			event.currentTarget === childNode
		)
			childrenProps.onMouseOver(event);

		if (ignoreNonTouchEvents.current && event.type !== 'touchstart') return;

		if (childNode) childNode.removeAttribute('title');

		clearTimeout(enterTimer.current);
		clearTimeout(leaveTimer.current);
		if (enterDelay && !delayOpen) {
			event.persist();
			enterTimer.current = setTimeout(() => {
				handleOpen(event);
			}, enterDelay);
		} else {
			handleOpen(event);
		}
	};

	const { isFocusVisible, onBlurVisible, ref: focusVisibleRef } = useIsFocusVisible();
	const [childIsFocusVisible, setChildIsFocusVisible] = useState(false);
	const handleBlur = () => {
		if (childIsFocusVisible) {
			setChildIsFocusVisible(false);
			onBlurVisible();
		}
	};

	const handleFocus = event => {
		if (!childNode) {
			setChildNode(event.currentTarget);
		}
		if (isFocusVisible(event)) {
			setChildIsFocusVisible(true);
			handleEnter(event);
		}
		const childrenProps = children.props;
		if (childrenProps.onFocus && event.currentTarget === childNode) {
			childrenProps.onFocus(event);
		}
	};

	const handleClose = event => {
		clearTimeout(delayTimer);
		delayTimer = setTimeout(() => {
			delayOpen = false;
		}, 500);
		setOpenState(false);
		if (onClose) {
			onClose(event);
		}
		clearTimeout(closeTimer.current);
		closeTimer.current = setTimeout(() => {
			ignoreNonTouchEvents.current = false;
		}, duration.shortest);
	};

	const handleLeave = event => {
		const childrenProps = children.props;
		if (event.type === 'blur') {
			if (childrenProps.onBlur && event.currentTarget === childNode) {
				childrenProps.onBlur(event);
			}
			handleBlur(event);
		}
		if (
			event.type === 'mouseleave' &&
			childrenProps.onMouseLeave &&
			event.currentTarget === childNode
		)
			childrenProps.onMouseLeave(event);
		clearTimeout(enterTimer.current);
		clearTimeout(leaveTimer.current);
		event.persist();
		leaveTimer.current = setTimeout(() => {
			handleClose(event);
		}, leaveDelay);
	};

	const handleTouchStart = event => {
		ignoreNonTouchEvents.current = true;
		const childrenProps = children.props;

		if (childrenProps.onTouchStart) {
			childrenProps.onTouchStart(event);
		}

		clearTimeout(leaveTimer.current);
		clearTimeout(closeTimer.current);
		clearTimeout(touchTimer.current);
		event.persist();
		touchTimer.current = setTimeout(() => {
			handleEnter(event);
		}, enterTouchDelay);
	};

	const handleTouchEnd = event => {
		if (children.props.onTouchEnd) {
			children.props.onTouchEnd(event);
		}

		clearTimeout(touchTimer.current);
		clearTimeout(leaveTimer.current);
		event.persist();
		leaveTimer.current = setTimeout(() => {
			handleClose(event);
		}, leaveTouchDelay);
	};

	const handleUseRef = useForkRef(setChildNode, ref);
	const handleFocusRef = useForkRef(focusVisibleRef, handleUseRef);
	const handleOwnRef = useCallback(
		instance => {
			setRef(handleFocusRef, findDOMNode(instance));
		},
		[handleFocusRef]
	);
	const handleRef = useForkRef(children.ref, handleOwnRef);

	if (title === '') {
		open = false;
	}

	const shouldShowNativeTitle = !open && !disableHoverListener;
	const childrenProps = {
		'aria-describedby': open ? id : null,
		title: shouldShowNativeTitle && typeof title === 'string' ? title : null,
		...other,
		...children.props,
		className: classNames(className, children.props.className),
	};

	if (!disableTouchListener) {
		childrenProps.onTouchStart = handleTouchStart;
		childrenProps.onTouchEnd = handleTouchEnd;
	}

	if (!disableHoverListener) {
		childrenProps.onMouseOver = handleEnter;
		childrenProps.onMouseLeave = handleLeave;
	}

	if (!disableFocusListener) {
		childrenProps.onFocus = handleFocus;
		childrenProps.onBlur = handleLeave;
	}

	const interactiveWrapperListeners = interactive
		? {
				onMouseOver: childrenProps.onMouseOver,
				onMouseLeave: childrenProps.onMouseLeave,
				onFocus: childrenProps.onFocus,
				onBlur: childrenProps.onBlur,
		  }
		: {};

	const popperOptions = useMemo(
		() => ({
			modifiers: [],
		}),
		[]
	);

	return (
		<Fragment>
			{cloneElement(children, { ref: handleRef, ...childrenProps })}
			<Popper
				className={classNames('popper', {
					popper_interactive: interactive,
					popper_arrow: arrow,
					[`popper_placement-${placement}`]: placement,
				})}
				arrow
				placement={placement}
				anchorEl={childNode}
				open={childNode ? open : false}
				id={childrenProps['aria-describedby']}
				transition
				popperOptions={popperOptions}
				{...interactiveWrapperListeners}
				{...PopperProps}
			>
				{({
					placement: placementInner,
					arrowStyle,
					TransitionProps: TransitionPropsInner,
				}) => (
					<TransitionComponent
						timeout={duration.shorter}
						{...TransitionPropsInner}
						{...TransitionProps}
					>
						<div
							className={classNames('tooltip', {
								tooltip_touch: ignoreNonTouchEvents.current,
								tooltip_arrow: arrow,
								[`tooltip_placement-${placementInner}`]: placementInner,
							})}
						>
							<div dangerouslySetInnerHTML={{ __html: title }} />
							{arrow && <span className="tooltip-arrow" style={arrowStyle} />}
						</div>
					</TransitionComponent>
				)}
			</Popper>
		</Fragment>
	);
});

export default Tooltip;
