import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const EditModeSwitch = ({
	text = 'Режим редактирования',
	editModeOn,
	switchEditModeHandler,
	name,
	id,
	disabled = false,
}) => {
	return (
		<label className={editModeOn ? 'edit-switch active' : 'edit-switch'}>
			<span className="edit-switch__text">{text}</span>
			<input
				type="checkbox"
				onChange={switchEditModeHandler}
				name={name}
				value={editModeOn}
				id={id}
				disabled={disabled}
			/>
			<span className="edit-switch__slider"></span>
		</label>
	);
};

EditModeSwitch.propTypes = {
	text: PropTypes.string,
	editModeOn: PropTypes.bool.isRequired,
	switchEditModeHandler: PropTypes.func.isRequired,
	name: PropTypes.string,
};
