import React from 'react';
import { Link } from 'react-router-dom';

import { clearStoreSearchResult, textColor } from '../../utils';
import './style.scss';

const clearSearchResultHandler = closeHandle => {
	if (closeHandle) closeHandle();
	clearStoreSearchResult();
};

export const ContactItem = ({ name, categoryIdBreadCrumb, fillText, indexName, closeHandle }) => {
	return (
		<Link
			to={
				categoryIdBreadCrumb
					? `/v2/contacts/${categoryIdBreadCrumb.replace(
							/^\//,
							''
					  )}?fromIndex=${indexName}`
					: null
			}
			className="block-item block-item_contact"
			onClick={() => clearSearchResultHandler(closeHandle)}
		>
			{textColor(name, fillText)}
		</Link>
	);
};
