import { api } from './api';
import showNotification from './showNotification';

//Получить информацию о текущем пользователе
export const getShortUserInfo = context => {
	api.get('/user/short-cur-user-info')
		.then(res => {
			const { response, responseCode } = res.data;
			if (responseCode === 0) {
				context.props.setShortUserInfo(response);
				context.props.showWelcomeMessage(response.needShowWelcome);
			}
		})
		.catch(() => {});
};

//Получение информации о пользователе
export const getFullUserInfo = (context, fromIndex, contactId) => {
	api.get(`/user/full-user-info`, {
		params: {
			id: context.props.match.params.id,
			...(fromIndex && contactId ? { fromIndex: fromIndex, contactId: contactId } : {}),
		},
	}).then(function (response) {
		if (response.data.responseCode === 0) {
			context.props.setFullUserInfo(response.data.response);
		}
	});
};

//Изменить информацию о пользователе
export const updateUserInfo = context => {
	api.post('/user/update', {
		workPhone: context.props.fullUserInfo.workPhone,
		email: context.props.fullUserInfo.email,
		telegram: context.state.telegram,
		skype: context.state.skype,
		viber: context.state.viber,
		vk: context.state.vk,
		web: context.state.web,
		whatsapp: context.state.whatsapp,
		workMobilePhone: context.props.fullUserInfo.workMobilePhone,
		zone: context.state.zone,
		address: context.props.fullUserInfo.address,
		loginPhoneIsVisible: context.state.loginPhoneIsVisible,
		birthday: context.state.birthday,
		birthYearIsVisible: context.state.birthYearIsVisible,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				showNotification(context, true, 0, 'Информация обновлена');
				context.props.updateFullUserInfo(response.data.response);
				context.setState({
					isEdit: false,
				});
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `updateUserInfo - ${error}`);
		});
};

//Изменить фотографию пользователя
export const updateUserImage = (context, image, originalFileName) => {
	api.post('/user/update-avatar', {
		originalFileName,
		image: image.slice(image.indexOf(',') + 1),
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.updateUserPhoto(response.data.response);
				showNotification(context, true, 0, 'Изображение изменено');
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `updateUserImage - ${error}`);
		});
};

//Получить список поощрений пользователя
export const getStimulationsList = (context, userId) => {
	if (!userId) {
		window.location = '/404';
		return;
	}

	api.get(`/user/stimulation?userId=${userId}`)
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.setStimulationList(response.data.response);
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/user/stimulation - ${error}`);
		});
};

export const getUserAvatar = async userId => {
	try {
		const response = await api.get(`/user/get-avatar?id=${userId}`);
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const getStatusesList = async () => {
	try {
		const response = await api.get(`/user/work-status/list`);
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const setStatusUser = async workStatus => {
	try {
		const response = await api.post(`/user/work-status/update`, { workStatus });
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};
