import { api } from './api';
import { COUNT_SEARCH } from '../constant';

//Поиск людей
export const searchUsersRightsItem = (query, withoutMe = false) => {
	return api.get(
		`/search-v2/find?indexName=USER&query=${query}&limit=${COUNT_SEARCH}&withoutMe=${withoutMe}`
	);
};

//Поиск тегов
export const searchTagsRightsItem = name => {
	return api.get(`/tags/find-by-value?value=${name}`);
};
