import React from 'react';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'core/components';
import { useAuth, useShowPass } from 'feature/auth/model';
import { getRestPhone } from 'plugins/getRestPhone';

const validationSchema = yup.object().shape({
	username: yup.string().required(),
	password: yup.string().required(),
});

export const Form = () => {
	const { showPass, toggleShowPass } = useShowPass();
	const { isLoading, errorMessage, onAuth } = useAuth();

	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const onSubmit = handleSubmit(async data => {
		const { username, password } = data;
		const clearUserName = getRestPhone(username);
		onAuth(clearUserName, password);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
			</svg>
			{!!errorMessage && <span className="auth-form-error-message">{errorMessage}</span>}
			<div className="auth-control-block">
				<label className="auth-control">
					<span className="auth-control__label">Телефон</span>
					<InputMask
						mask="+7 (999) 999-99-99"
						placeholder="+7 (___) ___-__-__"
						autoComplete="off"
						name="username"
						id="auth-control-phone"
						className={
							errors?.username ? 'validation-input error-input' : 'validation-input'
						}
						inputRef={register}
					/>
				</label>
			</div>
			<div className="auth-control-block auth-login-block">
				<label className="auth-control auth-control_password">
					<span className="auth-control__label">Пароль</span>
					<input
						type={showPass ? 'text' : 'password'}
						name="password"
						id="auth-control-password"
						className={
							errors?.password
								? 'validation-input error-input auth-control-text-field_password'
								: 'validation-input auth-control-text-field_password'
						}
						ref={register}
					/>
					<span
						onClick={toggleShowPass}
						tabIndex={-1}
						className="auth-login-show-pass-btn"
					>
						{showPass ? <EyeOff size={12} /> : <Eye size={12} />}
					</span>
				</label>
			</div>
			<Button
				type={isLoading ? 'done' : 'primary'}
				typeButton="submit"
				text="Вход"
				image={null}
				className="auth-action-btn"
				disabled={isLoading}
				id="auth-btn"
			/>
			<div className="auth-form-footer">
				<Link to="/register" className="entryPage__link red-text">
					Регистрация
				</Link>
				<Link to="/recover" className="entryPage__link red-text">
					Восстановление пароля
				</Link>
			</div>
		</form>
	);
};
