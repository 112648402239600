import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import MainPage from './mainPage';
import Articles from './new-article';

import AuthPage from 'pages/auth';
import AuthRegisterPages from 'pages/auth/register';
import AuthRecoverPage from 'pages/auth/recover';

export default class Router extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={MainPage} />
				<Route exact path="/main" component={MainPage} />
				<Route exact path="/main/thanks/:thankId" component={MainPage} />
				<Route exact path="/main/news/:idPost" component={MainPage} />
				<Route exact path="/main/news/:idPost/:idPoll" component={MainPage} />

				<Route exact strict path="media" component={MainPage} />
				<Route exact strict path="socket" component={MainPage} />

				<Route exact strict path="/menu/nav" component={MainPage} />
				<Route exact strict path="/menu/search" component={MainPage} />

				<Route exact path="/admin" component={MainPage} />

				<Route exact path="/login" component={AuthPage} />
				<Route exact path="/register" component={AuthRegisterPages} />
				<Route exact path="/recover" component={AuthRecoverPage} />

				<Route exact path="/v2/contacts" component={MainPage} />
				<Route exact path="/v2/contacts/:id*" component={MainPage} />

				<Route exact strict path="/user/:id" component={MainPage} />
				<Route
					exact
					path="/user/:id/congratulation/:congratulationId"
					component={MainPage}
				/>
				<Route exact path="/user/:id/achievement/:achievementId" component={MainPage} />
				<Route exact path="/user/:id/club-card" component={MainPage} />
				<Route exact path="/user/:id/notification" component={MainPage} />
				<Route exact path="/user/:id/claims" component={MainPage} />
				<Route exact path="/user/:id/claims/:claimId" component={MainPage} />
				<Route exact path="/user/:id/:thanksId" component={MainPage} replace />
				<Route exact path="/user/:id/approvement-requests" component={MainPage} />
				<Route
					exact
					path="/user/:id/:approvement-requests/:approvementId"
					component={MainPage}
				/>

				<Route exact path="/news" component={MainPage} />
				<Route exact path="/news/thanks/:thankId" component={MainPage} />
				<Route exact path="/news/:idPost" component={MainPage} />
				<Route exact path="/news/:idPost/:idPoll" component={MainPage} />

				<Route exact path="/group" component={MainPage} />
				<Route exact path="/group/:id" component={MainPage} />
				<Route exact path="/group-create" component={MainPage} />
				<Route exact path="/group/:id/members" component={MainPage} />
				<Route exact path="/group/:id/edit" component={MainPage} />
				<Route exact path="/group/:id/albums" component={MainPage} />
				<Route exact path="/group/:id/albums/create" component={MainPage} />
				<Route exact path="/group/:id/albums/:albumId" component={MainPage} />
				<Route exact path="/group/:id/albums/:albumId/:photoId" component={MainPage} />
				<Route exact path="/group/:id/article" component={MainPage} />

				<Route exact path="/group/:id/article/create" component={Articles} />
				<Route exact path="/group/:id/article/edit/:idArticle" component={Articles} />
				<Route exact path="/group/:id/article/:idArticle" component={Articles} />

				<Route path="/knowledge/documents" component={MainPage} />

				<Route exact path="/knowledge/guide/:id*/article/create" component={Articles} />
				<Route exact path="/knowledge/guide/:id*/article/:articleId" component={Articles} />
				<Route
					exact
					path="/knowledge/guide/:id*/article/:articleId/edit"
					component={Articles}
				/>

				<Route exact path="/group/:id/:idPost" component={MainPage} />
				<Route exact path="/group/:id/:idPoll" component={MainPage} />

				<Route exact path="/thanks" component={MainPage} />
				<Route exact path="/thanks/:id" component={MainPage} />

				<Route exact path="/vacancies" component={MainPage} />
				<Route exact path="/vacancies/create" component={MainPage} />
				<Route exact path="/vacancies/:id" component={MainPage} />
				<Route exact path="/vacancies/:id/edit" component={MainPage} />
				<Route exact path="/vacancies/rules" component={MainPage} />

				<Route exact path="/club" component={MainPage} />

				<Route exact path="/appointments" component={MainPage} />
				<Route exact path="/appointments/:id" component={MainPage} />

				<Route exact path="/history" component={MainPage} />

				<Route exact path="/corporate-values" component={MainPage} />

				<Route exact path="/search-result/:text" component={MainPage} />

				<Route path="/strategy" component={MainPage} />

				<Route exact path="/maxiProfessional" component={MainPage} />

				<Route exact path="/walkfame" component={MainPage} />
				<Route exact path="/walkfame/create" component={MainPage} />
				<Route exact path="/walkfame/all" component={MainPage} />
				<Route exact path="/walkfame/:id" component={MainPage} />
				<Route exact path="/walkfame/edit/:id" component={MainPage} />
				<Route exact path="/walkfame/:id/edit/:userId" component={MainPage} />
				<Route exact path="/walkfame/:id/album" component={MainPage} />
				<Route exact path="/walkfame/:id/:userId" component={MainPage} />

				<Route exact path="/birthday" component={MainPage} />

				<Route exact path="/forbidden" component={MainPage} />

				<Route exact path="/search" component={MainPage} />
				<Route exact path="/search/:entity/:query" component={MainPage} />

				<Route exact path="/services" component={MainPage} />
				<Route exact path="/services/carFix" component={MainPage} />
				<Route exact path="/services/doctor" component={MainPage} />
				<Route exact path="/services/doctor/edit" component={MainPage} />
				<Route exact path="/services/doctor/all" component={MainPage} />
				<Route exact path="/services/doctor/waiting-list" component={MainPage} />

				<Route path="/services/courier" component={MainPage} />

				<Route path="/service/vacation" component={MainPage} />

				<Route path="/services/hot-line" component={MainPage} />

				<Route exact path="/services/statements/:id" component={MainPage} />

				<Route
					exact
					path="/v2/services/:id"
					render={props => <MainPage {...props} withoutEmilia={true} />}
				/>
				<Route
					exact
					path="/v2/services/:id/:categoryId*/ticket/:ticketId"
					render={props => <MainPage {...props} withoutEmilia={true} />}
				/>
				<Route
					exact
					path="/v2/services/:id/:categoryId*"
					render={props => <MainPage {...props} withoutEmilia={true} />}
				/>

				<Route exact path="/services/business-card" component={MainPage} />

				<Route path="/education" component={MainPage} />

				<Route exact path="/services/passes/:id/admin" component={MainPage} />
				<Route exact path="/services/passes/:id/:categoryId" component={MainPage} />

				<Route exact path="/services/trips/:id" component={MainPage} />
				<Route exact path="/services/trips/:id/trip/:tripId" component={MainPage} />

				<Route exact path="/services/retail-shifts" component={MainPage} />
				<Route exact path="/services/retail-shifts/:id" component={MainPage} />

				<Route exact path="/university" component={MainPage} />
				<Route exact path="/university/literature" component={MainPage} />

				<Route exact path="/competencies" component={MainPage} />

				<Route exact path="/structure" component={MainPage} />

				<Route exact path="/favorites" component={MainPage} />

				<Route exact path="/updates" component={MainPage} />

				<Route exact path="/gallery" component={MainPage} />

				<Route exact path="/menu" component={MainPage} />

				<Route exact path="/404" component={MainPage} />

				<Route exact path="/mirapolis-mobile" component={MainPage} />

				<Route path="/mentoring" component={MainPage} />

				<Route path="/achievements" component={MainPage} />

				<Route path="/quiz" component={MainPage} />
				<Route path="/cropper" component={MainPage} />

				<Route path="/ltr" component={MainPage} />

				<Route path="/service/surveys" component={MainPage} />
				<Route path="/special-survey" component={MainPage} />

				<Route path="/service/workplace" component={MainPage} />

				<Route path="/messenger" component={MainPage} />

				<Route path="/VHI" component={MainPage} />

				<Route path="/services/repair" component={MainPage} />

				<Route component={MainPage} />
			</Switch>
		);
	}
}
