import {
	ADD_QUESTION,
	SET_QUESTIONS,
	DELETE_QUESTIONS,
	UPDATE_QUESTION_TITLE,
	UPDATE_QUESTION_MIN,
	UPDATE_QUESTION_MAX,
	ADD_QUESTION_ANSWER,
	UPDATE_QUESTION_ANSWER,
	DELETE_QUESTION_ANSWER,
	ADD_QUESTION_SCALE,
	DELETE_QUESTION,
	CHANGE_QUESTION_INDEX,
	ADD_QUESTION_CONDITION,
	UPDATE_QUESTION_CONDITION_ID,
	UPDATE_QUESTION_CONDITION_VALUE,
	DELETE_QUESTION_CONDITION,
	UPDATE_SURVEY_TITLE,
	UPDATE_SURVEY_DESCRIPTION,
	UPDATE_SURVEY_START_DATE,
	UPDATE_SURVEY_END_DATE,
	UPDATE_SURVEY_DEPARTMENS,
	UPDATE_SURVEY_USERS,
	UPDATE_SURVEY_PREVIEW,
	UPDATE_SURVEY_ANONYMOUS,
	UPDATE_CONDITION_TYPE,
	SET_SURVEY,
	CHANGE_QUESTION_TYPE,
	QUESTION_IMAGE,
	CHANGE_ANSWER_OTHER_TYPE,
	UPDATE_SURVEY_POSITIONS,
	COPY_SURVEY,
	UPDATE_SURVEY_SPECIAL,
	UPDATE_SURVEY_FINISH_TEXT,
	UPDATE_SURVEY_FINISH_BUTTON_NAME,
	UPDATE_SURVEY_FINISH_BUTTON_LINK,
} from 'constant';

const DefaultState = {
	title: '',
	description: '',
	startDate: null,
	endDate: null,
	anonymous: false,
	special: false,
	cover: null,
	questions: [],
	forUsers: [],
	forDepartments: [],
	forPositions: [],
	finishText: '',
	finishButtonName: '',
	finishButtonLink: '',
};

export default function survey(state = DefaultState, action) {
	switch (action.type) {
		case UPDATE_SURVEY_TITLE:
			return {
				...state,
				title: action.title,
			};

		case UPDATE_SURVEY_DESCRIPTION:
			return {
				...state,
				description: action.description,
			};

		case UPDATE_SURVEY_START_DATE:
			return {
				...state,
				startDate: action.startDate,
			};

		case UPDATE_SURVEY_END_DATE:
			return {
				...state,
				endDate: action.endDate,
			};

		case UPDATE_SURVEY_USERS:
			return {
				...state,
				forUsers: action.forUsers,
			};

		case UPDATE_SURVEY_DEPARTMENS:
			return {
				...state,
				forDepartments: action.forDepartments,
			};

		case UPDATE_SURVEY_POSITIONS:
			return {
				...state,
				forPositions: action.forPositions,
			};

		case UPDATE_SURVEY_PREVIEW:
			return {
				...state,
				cover: action.cover,
			};

		case UPDATE_SURVEY_ANONYMOUS:
			return {
				...state,
				anonymous: action.anonymous,
			};

		case UPDATE_SURVEY_SPECIAL:
			return {
				...state,
				special: action.special,
			};

		case UPDATE_SURVEY_FINISH_TEXT:
			return {
				...state,
				finishText: action.text,
			};

		case UPDATE_SURVEY_FINISH_BUTTON_NAME:
			return {
				...state,
				finishButtonName: action.text,
			};

		case UPDATE_SURVEY_FINISH_BUTTON_LINK:
			return {
				...state,
				finishButtonLink: action.text,
			};

		case SET_SURVEY:
			return {
				...action.survey,
			};

		case ADD_QUESTION:
			return {
				...state,
				questions: [...state.questions, action.question],
			};

		case SET_QUESTIONS:
			return {
				...state,
				questions: [...state.questions, ...action.questions],
			};

		case QUESTION_IMAGE:
			return {
				...state,
				questions: [
					...state.questions.map(item => {
						if (item.questionNumber === action.questionNumber) {
							item.image = action.image;
						}
						return item;
					}),
				],
			};

		case DELETE_QUESTION:
			return {
				...state,
				questions: [
					...state.questions
						.filter(question => question.questionNumber !== action.questionNumber)
						.map((item, index) => {
							item.questionNumber = index + 1;
							return item;
						}),
				],
			};

		case DELETE_QUESTIONS:
			return {
				questions: [],
			};

		case UPDATE_QUESTION_TITLE:
			const updateQuestionTitle = state.questions.map(question => {
				if (question?.title && question.questionNumber === action.question.questionNumber) {
					question.title = action.question.title;
				}
				return question;
			});
			return {
				...state,
				questions: [...updateQuestionTitle],
			};

		case UPDATE_QUESTION_MIN:
			const updateQuestionMin = state.questions.map(question => {
				if (
					question?.minLabel &&
					question.questionNumber === action.question.questionNumber
				) {
					question.minLabel = action.question.minLabel;
				}
				return question;
			});
			return {
				...state,
				questions: [...updateQuestionMin],
			};

		case UPDATE_QUESTION_MAX:
			const updateQuestionMax = state.questions.map(question => {
				if (
					question?.maxLabel &&
					question.questionNumber === action.question.questionNumber
				) {
					question.maxLabel = action.question.maxLabel;
				}
				return question;
			});
			return {
				...state,
				questions: [...updateQuestionMax],
			};

		case ADD_QUESTION_ANSWER:
			const addQuestionAnswer = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.possibleAnswers.push(action.newAnswer);
				}
				return question;
			});
			return {
				...state,
				questions: [...addQuestionAnswer],
			};

		case UPDATE_QUESTION_ANSWER:
			const updateQuestionAnswer = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.possibleAnswers.map(answer => {
						if (answer.id === action.answerId) answer.text = action.value;
						return answer;
					});
				}
				return question;
			});
			return {
				...state,
				questions: [...updateQuestionAnswer],
			};

		case DELETE_QUESTION_ANSWER:
			return {
				...state,
				questions: [
					...state.questions.map(question => {
						if (question.questionNumber === action.questionNumber) {
							return {
								...question,
								possibleAnswers: [
									...question.possibleAnswers.filter(
										answer => answer.id !== action.answerId
									),
								],
							};
						} else return question;
					}),
				],
			};

		case ADD_QUESTION_CONDITION:
			const addQuestionCondition = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.conditions.push(action.condition);
				}
				return question;
			});
			return {
				...state,
				questions: [...addQuestionCondition],
			};

		case UPDATE_QUESTION_CONDITION_ID:
			const updateQuestionConditionId = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.conditions.map(condition => {
						if (condition.index === action.condition.index) {
							condition.id = action.condition.id;
							condition.type = action.condition.type;
							condition.questionNumber = action.condition.questionNumber;
							condition.questionType = action.condition.questionType;
						}
						return condition;
					});
				}
				return question;
			});
			return {
				...state,
				questions: [...updateQuestionConditionId],
			};

		case UPDATE_QUESTION_CONDITION_VALUE:
			const updateQuestionConditionValue = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.conditions.map(condition => {
						if (condition.index === action.index) {
							condition.value = action.value;
						}
						return condition;
					});
				}
				return question;
			});
			return {
				...state,
				questions: [...updateQuestionConditionValue],
			};

		case UPDATE_CONDITION_TYPE:
			const updateConditionType = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.conditions.map(condition => {
						if (condition.index === action.index) {
							condition.type = action.conditionType;
						}
						return condition;
					});
				}
				return question;
			});
			return {
				...state,
				questions: [...updateConditionType],
			};

		case DELETE_QUESTION_CONDITION:
			return {
				...state,
				questions: [
					...state.questions.map(question => {
						if (question.questionNumber === action.questionNumber) {
							return {
								...question,
								conditions: [
									...question.conditions.filter(
										condition => condition.index !== action.deleteIndex
									),
								],
							};
						} else return question;
					}),
				],
			};

		case ADD_QUESTION_SCALE:
			state.questions.map(question => {
				if (question?.max && question.questionNumber === action.question.questionNumber) {
					question.max = action.question.max;
				}
				return question;
			});
			return {
				...state,
				questions: [...state.questions],
			};

		case CHANGE_QUESTION_INDEX:
			const saveElement = state.questions[action.changeIndex];

			state.questions[action.changeIndex] = state.questions[action.changeIndex + 1];
			state.questions[action.changeIndex + 1] = saveElement;

			state.questions[action.changeIndex].questionNumber = saveElement.questionNumber;
			state.questions[action.changeIndex + 1].questionNumber = saveElement.questionNumber + 1;

			state.questions.forEach(question => {
				question.conditions.forEach(condition => {
					state.questions.forEach(item => {
						if (condition.id === item.id) {
							condition.questionNumber = item.questionNumber;
						}
					});
				});
			});

			return {
				...state,
				questions: [...state.questions],
			};

		case CHANGE_QUESTION_TYPE:
			const changeQuestionType = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					action.data.title = question.title;
					question = {
						...action.data,
						questionNumber: action.questionNumber,
					};
				}
				return question;
			});
			return {
				...state,
				questions: changeQuestionType,
			};

		case CHANGE_ANSWER_OTHER_TYPE:
			const changeAnswerOther = state.questions.map(question => {
				if (question.questionNumber === action.questionNumber) {
					question.withOwnAnswer = action.state;
				}
				return question;
			});
			return {
				...state,
				questions: [...changeAnswerOther],
			};

		case COPY_SURVEY:
			return {
				...action.survey,
				startDate: null,
				endDate: null,
				forUsers: [],
				forDepartments: [],
				forPositions: [],
			};

		default:
			return state;
	}
}
