import { useState, useCallback, SyntheticEvent } from 'react';

export const useShowPass = () => {
	const [showPass, setShowPass] = useState(false);

	const toggleShowPass = useCallback((e: SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setShowPass(prev => !prev);
	}, []);

	return {
		showPass,
		toggleShowPass,
	};
};
