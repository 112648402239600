import React from 'react';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'core/components';
import { useFormState, useRegistrationStart } from 'feature/auth-register/model';
import { getRestPhone } from 'plugins/getRestPhone';

const validationSchema = yup.object().shape({
	phone: yup.string().required(),
});

export const PhoneForm = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const { phone, setPhone } = useFormState();
	const { isLoading, errorMessage, getCode } = useRegistrationStart();

	const onSubmit = handleSubmit(data => {
		setPhone(data.phone);
		const clearPhone = getRestPhone(data.phone);
		getCode(clearPhone);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
			</svg>
			{!!errorMessage && <span className="auth-form-error-message">{errorMessage}</span>}
			<div className="auth-control-block">
				<label className="auth-control">
					<span className="auth-control__label">Введите Ваш личный номер телефона</span>
					<InputMask
						mask="+7 (999) 999-99-99"
						placeholder="+7 (___) ___-__-__"
						autoComplete="off"
						name="phone"
						id="auth-registration-control-phone"
						defaultValue={phone}
						className={
							errors?.phone ? 'validation-input error-input' : 'validation-input'
						}
						inputRef={register}
					/>
				</label>
			</div>
			<Button
				type="primary"
				typeButton="submit"
				text="Получить код подтверждения"
				image={null}
				className="auth-action-btn"
				id="auth-registration-phone-btn"
				disabled={isLoading}
			/>
			<div className="auth-form-footer">
				<Link to="/login" className="entryPage__link red-text">
					Вернуться
				</Link>
			</div>
		</form>
	);
};
