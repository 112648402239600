import React, { FC, SyntheticEvent } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'core/components';
import { useFormState, useRecoverNewPassword, useShowPass } from 'feature/auth-recover/model';

const validationSchema = yup.object().shape({
	password: yup.string().min(8).max(32).required(),
	passwordCopy: yup
		.string()
		.required()
		.oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

export const NewPasswordForm: FC = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const { showPass, toggleShowPass } = useShowPass();
	const { showPass: showPassCopy, toggleShowPass: toggleShowPassCopy } = useShowPass();

	const { setPhoneView } = useFormState();

	const { isLoading, errorMessage, setNewPassword } = useRecoverNewPassword();

	const onLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		setPhoneView();
	};

	const onSubmit = handleSubmit(data => {
		const { password } = data;
		setNewPassword(password);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
			</svg>
			{!!errorMessage && <span className="auth-form-error-message">{errorMessage}</span>}
			<div className="auth-new-pass-title">
				<span>Код подтверждения принят</span>
			</div>
			<div className="auth-control-block">
				<label className="auth-control auth-new-pass-control_password">
					<span className="auth-control__label">Придумайте надежный пароль</span>
					<input
						type={showPass ? 'text' : 'password'}
						name="password"
						id="auth-recover-new-code-password"
						className={
							errors?.password
								? 'validation-input error-input auth-new-pass-control-text-field_password'
								: 'validation-input auth-new-pass-control-text-field_password'
						}
						ref={register}
					/>
					<button onClick={toggleShowPass} className="auth-new-pass-login-show-pass-btn">
						{showPass ? <EyeOff size={12} /> : <Eye size={12} />}
					</button>
				</label>
			</div>
			<div className="auth-new-pass-description">
				<span>Пароль должен содержать от 8 до 32 символов</span>
			</div>
			<div className="auth-control-block auth-new-pass-control-block">
				<label className="auth-control auth-new-pass-control_password">
					<span className="auth-control__label">Подтвердите пароль</span>
					<input
						type={showPassCopy ? 'text' : 'password'}
						name="passwordCopy"
						id="auth-recover-new-code-password-copy"
						className={
							errors?.passwordCopy
								? 'validation-input error-input auth-new-pass-control-text-field_password'
								: 'validation-input auth-new-pass-control-text-field_password'
						}
						ref={register}
					/>
					<button
						onClick={toggleShowPassCopy}
						className="auth-new-pass-login-show-pass-btn"
					>
						{showPassCopy ? <EyeOff size={12} /> : <Eye size={12} />}
					</button>
				</label>
			</div>
			<Button
				type="primary"
				typeButton="submit"
				text="Войти"
				image={null}
				className="auth-action-btn"
				id="auth-recover-new-code-btn"
				disabled={isLoading}
			/>
			<div className="auth-form-footer">
				<a href="/" onClick={onLinkClick} className="entryPage__link red-text">
					Вернуться
				</a>
			</div>
		</form>
	);
};
