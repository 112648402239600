import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'core/components';
import {
	useFormState,
	useRegistrationConfirm,
	useRegistrationStart,
} from 'feature/auth-register/model';
import { getRestPhone } from 'plugins/getRestPhone';
import { Countdown } from 'shared/ui/Countdown';

const validationSchema = yup.object().shape({
	code: yup.string().required(),
});

export const CodeForm: FC = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});
	const [theEnd, setEnd] = useState(false);
	const { setPhoneView, phone, deadline } = useFormState();
	const { isLoading, errorMessage, sendCode } = useRegistrationConfirm();
	const {
		isLoading: isLoadingGetCode,
		errorMessage: errorMessageGetCode,
		getCode,
	} = useRegistrationStart();

	useEffect(() => setEnd(false), [deadline]);

	const onLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		setPhoneView();
	};

	const onGetCodeClick = (e: SyntheticEvent) => {
		e.preventDefault();
		const clearPhone = getRestPhone(phone);
		getCode(clearPhone);
	};

	const onSubmit = handleSubmit(data => {
		sendCode(data.code);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
			</svg>
			{!!errorMessage && <span className="auth-form-error-message">{errorMessage}</span>}
			{!!errorMessageGetCode && (
				<span className="auth-form-error-message">{errorMessageGetCode}</span>
			)}
			<div className="auth-control-block">
				<label className="auth-control">
					<span className="auth-control__label">
						Введите код из SMS-сообщения. Код подтверждения отправлен на Ваш номер
						телефона
						<br />
						{phone}
					</span>
					<input
						autoComplete="off"
						name="code"
						id="auth-registration-control-code"
						ref={register}
						className={
							errors?.code ? 'validation-input error-input' : 'validation-input'
						}
					/>
				</label>
			</div>
			<Button
				type="primary"
				typeButton="submit"
				text="Отправить"
				image={null}
				className="auth-action-btn"
				id="auth-registration-code-btn"
				disabled={isLoading || isLoadingGetCode}
			/>
			<div className="auth-code-form-no-message">
				<span className="auth-code-form-no-message__title">Не пришло сообщение?</span>
				<button
					className="auth-code-form-no-message__btn"
					id="auth-registration-code-sms"
					onClick={onGetCodeClick}
					disabled={isLoading || isLoadingGetCode || !theEnd}
				>
					Получить новый код{' '}
					{!theEnd && (
						<>
							через <Countdown deadline={deadline} setEnd={setEnd} />
						</>
					)}
				</button>
			</div>
			<div className="auth-form-footer">
				<a href="/" onClick={onLinkClick} className="entryPage__link red-text">
					Вернуться
				</a>
			</div>
		</form>
	);
};
