import React from 'react';
import { useSelector } from 'react-redux';
import { URL } from 'constant';
import { Link } from 'react-router-dom';

import { Avatar, Roles } from 'core/components';

import { clearStoreSearchResult, textColor } from '../../utils';

import './style.scss';
import { Clock } from 'react-feather';

const clearSearchResultHandler = closeHandle => {
	if (closeHandle) closeHandle();
	clearStoreSearchResult();
};

export const UserItem = ({
	id,
	name,
	position,
	telephone,
	userId,
	avatarFile,
	fillText,
	address,
	ipPhone,
	categoryIdBreadCrumb,
	workStatus,
	statuses,
	indexName,
	closeHandle,
}) => {
	const rmeId = useSelector(state => state.options?.rmeId);

	if (position === null && categoryIdBreadCrumb) {
		return (
			<div className="block-item block-item_user block-item_userService">
				<Link
					to={`/v2/contacts${categoryIdBreadCrumb}?fromIndex=${indexName}&contactId=${id}`}
					className="user-info"
					onClick={() => clearSearchResultHandler(closeHandle)}
				>
					<Avatar type="user" size="small" image={null} />
					<div className="info-wrapp">
						<span className="info-name">{textColor(name, fillText)}</span>
						<span className="info-position">{textColor(address, fillText)}</span>

						{!!workStatus && workStatus !== 'NONE' && !!statuses && (
							<div className="info-status">
								<Clock className="info-status__icon" />
								<span className="info-status__text">
									{
										statuses.find(item => item.statusName === workStatus)
											?.statusDesc
									}
								</span>
							</div>
						)}
					</div>
				</Link>
				<div className="user-telephone">{renderTelephone(telephone, fillText)}</div>
			</div>
		);
	} else {
		return (
			<div className="block-item block-item_user">
				<Link
					to={`/user/${userId}?fromIndex=${indexName}&contactId=${id}`}
					className="user-info"
					onClick={() => clearSearchResultHandler(closeHandle)}
				>
					<Avatar
						type="user"
						size="small"
						image={avatarFile ? `${URL}${avatarFile}` : null}
					/>
					<div className="info-wrapp">
						<span className="info-name">{textColor(name, fillText)}</span>
						{/* Если не РМЕ - выводим должность */}
						<Roles isView={+userId !== +rmeId}>
							<span className="info-position">{textColor(position, fillText)}</span>
						</Roles>
						<span className="info-address">{textColor(address, fillText)}</span>

						{!!workStatus && workStatus !== 'NONE' && !!statuses && (
							<div className="info-status">
								<Clock className="info-status__icon" />
								<span className="info-status__text">
									{
										statuses.find(item => item.statusName === workStatus)
											?.statusDesc
									}
								</span>
							</div>
						)}
					</div>
				</Link>
				<div className="user-telephone__block">
					<div className="user-telephone">{renderTelephone(telephone, fillText)}</div>

					{ipPhone && (
						<div className="user-telephone">{renderIPTelephone(ipPhone, fillText)}</div>
					)}
				</div>
			</div>
		);
	}
};

const renderTelephone = (telephone, fillText) => {
	if (telephone) {
		const arrayPhone = telephone.split(/\n/);
		return arrayPhone.map((item, index) => {
			return (
				<a key={index} href={`tel:${item}`} tabIndex="-1">
					{textColor(item, fillText)}
				</a>
			);
		});
	}
};

const renderIPTelephone = (telephone, fillText) => {
	if (telephone) {
		const arrayPhone = telephone.split(/\n/);
		return arrayPhone.map((item, index) => {
			return (
				<span key={index} tabIndex="-1">
					{`IP-телефон: `} {textColor(item, fillText)}
				</span>
			);
		});
	}
};
