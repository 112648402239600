import React, { FC, SyntheticEvent, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, CheckBox, ModalComponent as Modal } from 'core/components';
import {
	useFormState,
	useRegistrationComplete,
	useShowPass,
	useShowPersonalDataModal,
} from 'feature/auth-register/model';
import { ModalContent } from './ModalContent';

const validationSchema = yup.object().shape({
	password: yup.string().min(8).max(32).required(),
	passwordCopy: yup
		.string()
		.required()
		.oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

export const NewPasswordForm: FC = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const [checked, setChecked] = useState(false);

	const { showPass, toggleShowPass } = useShowPass();
	const { showPass: showPassCopy, toggleShowPass: toggleShowPassCopy } = useShowPass();
	const { isOpen, toggleOpen } = useShowPersonalDataModal();

	const { isLoading, errorMessage, regComplete } = useRegistrationComplete();

	const { setPhoneView, userName } = useFormState();

	const onCbxChange = () => {
		setChecked(prev => !prev);
	};

	const onLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		setPhoneView();
	};

	const onPersonalDataLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		toggleOpen();
	};

	const onSubmit = handleSubmit(data => {
		if (checked) {
			const { password } = data;
			regComplete(password, true);
		}
	});

	return (
		<>
			{isOpen && (
				<Modal width="narrow" className="" onClose={toggleOpen}>
					<ModalContent />
				</Modal>
			)}
			<form className="auth-form" onSubmit={onSubmit}>
				<svg role="img" className="auth-logo">
					<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
				</svg>
				{!!errorMessage && <span className="auth-form-error-message">{errorMessage}</span>}
				<div className="auth-new-pass-user">
					<span>{userName}</span>
				</div>
				<div className="auth-new-pass-title">
					<span>Код подтверждения принят</span>
				</div>
				<div className="auth-control-block">
					<label className="auth-control auth-new-pass-control_password">
						<span className="auth-control__label">Придумайте надежный пароль</span>
						<input
							type={showPass ? 'text' : 'password'}
							name="password"
							id="auth-registration-new-code-password"
							className={
								errors?.password
									? 'validation-input error-input auth-new-pass-control-text-field_password'
									: 'validation-input auth-new-pass-control-text-field_password'
							}
							ref={register}
						/>
						<span
							onClick={toggleShowPass}
							className="auth-new-pass-login-show-pass-btn"
						>
							{showPass ? <EyeOff size={12} /> : <Eye size={12} />}
						</span>
					</label>
				</div>
				<div className="auth-new-pass-description">
					<span>Пароль должен содержать от 8 до 32 символов</span>
				</div>
				<div className="auth-control-block">
					<label className="auth-control auth-new-pass-control_password">
						<span className="auth-control__label">Подтвердите пароль</span>
						<input
							type={showPassCopy ? 'text' : 'password'}
							name="passwordCopy"
							id="auth-registration-new-code-password-copy"
							className={
								errors?.passwordCopy
									? 'validation-input error-input auth-new-pass-control-text-field_password'
									: 'validation-input auth-new-pass-control-text-field_password'
							}
							ref={register}
						/>
						<span
							onClick={toggleShowPassCopy}
							className="auth-new-pass-login-show-pass-btn"
						>
							{showPassCopy ? <EyeOff size={12} /> : <Eye size={12} />}
						</span>
					</label>
				</div>
				<div className="auth-new-pass-personal-data">
					<CheckBox
						name="personal-data"
						className=""
						disabled={false}
						label={null}
						checked={checked}
						onChange={onCbxChange}
						id="auth-new-pass-login-personal-data"
						renderLabel={
							<div className="auth-new-pass-personal-data-label">
								Я даю{' '}
								<a href="/" onClick={onPersonalDataLinkClick}>
									согласие
								</a>{' '}
								на обработку персональных данных
							</div>
						}
					/>
				</div>
				<Button
					type="primary"
					typeButton="submit"
					text="Зарегистрироваться"
					image={null}
					className="auth-action-btn"
					id="auth-registration-new-code-btn"
					disabled={isLoading}
				/>
				<div className="auth-form-footer">
					<a href="/" onClick={onLinkClick} className="entryPage__link red-text">
						Вернуться
					</a>
				</div>
			</form>
		</>
	);
};
